import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import { Link } from "gatsby"
import React from "react"
import Seo from "../components/seo"

const NewsPage = () => {

  // TODO GRM - date not equal to null to determine news posts vs model pages. 
  // Not good enough, should use a type field in the frontmatter or something
  const data = useStaticQuery(graphql`
  {
    news: allMdx(
      filter: {frontmatter: {date: { ne: null }}}
    ) {
      nodes {
        frontmatter {
          slug
          title
          desc
          hero {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, quality: 90)
            }
          }
          hero_alt
          date
        }
      }
    }
  
  }
    
  `);

  const news = data?.news?.nodes.map((x) => x.frontmatter);
 

  return (
    <Layout >
      <Seo title="News" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">News</h1>
        
        <ul className="mt-10">
          {news.map(x => (
              <li className="w-1/3 block relative rounded-lg overflow-hidden ">
                <Link to={`/news${x.slug}`} className="">  
                  <GatsbyImage image={getImage(x.hero)} alt={x.hero_alt} className="w-full h-full block pixelate" />
                </Link>
                <div className="p-5 ">
                  <h2 className="text-lg font-bold"> <Link to={`/news${x.slug}`} className="">{x.title}</Link></h2>
                  <p>{x.desc}</p>
                </div>
              </li>
          ))}
    </ul>
        
      </div>
    </Layout>
  )
}

export default NewsPage